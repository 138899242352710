<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-01-24 10:05:36
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-02-18 16:23:53
-->
<script setup lang='ts'>
import { LOAD_STATE } from '@/shared/types'

const props = withDefaults(defineProps<{
  state: LOAD_STATE
  loadMoreText?: string
  noMoreText?: string
}>(), {
  loadMoreText: '加载更多',
  noMoreText: '没有更多了',
})

const emits = defineEmits({
  load: () => true,
})
function clickHandler() {
  if (![LOAD_STATE.LOADING, LOAD_STATE.END].includes(props.state))
    emits('load')
}
</script>

<template>

  <button
    class="block w-full rounded text-center mt-2 py-1 text-3.5"
    :class="{
      'cursor-not-allowed bg-opacity-50': [LOAD_STATE.LOADING, LOAD_STATE.END].includes(props.state),
    }"
    :disabled="props.state === LOAD_STATE.LOADING"
    @click="clickHandler"
  >

    <template v-if="props.state === LOAD_STATE.LOADING">
      <i class="i-eos-icons:loading animate-spin" /> 加载中...
    </template>

    <template v-else-if="props.state === LOAD_STATE.END">
      <slot name="end">
        {{ props.noMoreText }}
      </slot>
    </template>
    <template v-else>
      <slot>
        {{ props.loadMoreText }}
      </slot>
    </template>
  </button>
</template>

<style lang='scss' scoped>

</style>
